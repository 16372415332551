import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function HeaderWithLayout() {

  const [navigation, setNavigation] = useState(0);

  const navigate = useNavigate();

  const handleNavigationBar = ()=>{
    setNavigation(!navigation);
  }

  const handleLogout = () =>{
    navigate("/");
    localStorage.removeItem("token");
    localStorage.removeItem("userType");
  }

  return <div className="header">
    <nav className="flex jcb aic">
      <div className="logo-container d-flex jcb aic">
        {/* <img src="" alt="" className="logo"> */}
        <img src="../Images/miira-logo.png" className="miira-logo" alt="logo" />

        <button className="btn nav-toggle" onClick={handleNavigationBar}><i className="fas fa-bars"></i></button>
      </div>
      <div className={ navigation ? "links-container on" : "links-container off"}>
        <ul className="flex jcb aic">
          <li>
            <a href="/" className="scroll-link">Home</a>
            <div></div>
          </li>
          <li>
            <a href="/schemes" className="scroll-link">Schemes</a>
            <div></div>
          </li>
          <li>
            <a href="/doctors" className="scroll-link">Doctors</a>
            <div></div>
          </li>
          <li>
            <a href="/contact" className="scroll-link">Contact</a>
            <div></div>
          </li>
          <li>
            <a href="/about" className="scroll-link">About</a>
            <div></div>
          </li>
        </ul>
      </div>
      <ul className="social-icons flex aic jcb">
        {/* <li>
          <a href="/" className="social-link"><i className="fab fa-facebook"></i></a>
        </li>
        <li>
          <a href="/" className="social-link"><i className="fab fa-google"></i></a>
        </li>
        <li>
          <a href="/" className="social-link"><i className="fab fa-twitter"></i></a>
        </li>
        <li>
          <a href="/" className="social-link"><i className="fab fa-linkedin"></i></a>
        </li> */}
        <button className="btn btn-logout" onClick={handleLogout}><i class="fa-solid fa-right-from-bracket"></i> Logout</button>
      </ul>
    </nav>
  </div>
};