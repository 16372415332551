import { useContext, useEffect, useState } from "react";
import PageLayout from "../layout/Pagelayout";
import { AccountContext } from "../context/AccountProvider";
import { useNavigate } from "react-router-dom";
import { DataUrl } from "../service/DataUrl";
import { addDoctor } from "../service/AddDoctor";
import { addScheme } from "../service/AddScheme";
import { Alert } from "react-bootstrap"
import { addToGallery } from "../service/AddToGallery";
import { getGalleryImages } from "../service/GetGallery";
import { addBanner } from "../service/AddBanner";
import { getBanners } from "../service/GetBanners";
import HeaderWithLayout from "../layout/HeaderWithLayout";

export default function Dashboard() {

    const navigate = useNavigate();

    const [form, setForm] = useState(1);
    const [formData, setFormData] = useState({});
    const [galleryForm, setGalleryForm] = useState({});
    const [galleryImages, setGalleryImages] = useState(null);
    const [bannerForm, setBannerForm] = useState(null);
    const [banners, setBanners] = useState(null);

    const [alert, setAlert] = useState({ state: false, text: "", variant: "" });

    const { account } = useContext(AccountContext)

    useEffect(() => {
        console.log(account);
        if (localStorage.getItem("userType") === "admin" && localStorage.getItem("token")) {
            console.log("Paras");
        } else {
            navigate("/admin");
        }
    }, [account])

    useEffect(() => {
        setTimeout(() => { setAlert({ state: false, variant: "", text: "" }) }, 10000);
    }, [alert])

    useEffect(() => {
        getImages();
        handleBanners();
    }, [])

    const handleChange = async (e) => {
        console.log("reached");
        if (e.target.files) {
            const dataUrl = await DataUrl(e.target.files);
            if (!dataUrl) {
                window.scrollTo(0, 0);
                setAlert({ state: true, variant: "danger", text: "Image size should be less that 250kb." })
            }
            setFormData({ ...formData, img: dataUrl });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value })
        }
    }

    const handleGalleryFormChange = async (e) => {
        if (e.target.files) {
            const dataUrl = await DataUrl(e.target.files);
            if (!dataUrl) {
                window.scrollTo(0, 0);
                setAlert({ state: true, variant: "danger", text: "Image size should be less that 250kb." })
            }
            setGalleryForm({ ...galleryForm, img: dataUrl })
        } else {
            setGalleryForm({ ...galleryForm, [e.target.name]: e.target.value });
        }
    }

    const handleBannerFormChange = async (e) => {
        if (e.target.files) {
            const dataUrl = await DataUrl(e.target.files, 700000);
            if (!dataUrl) {
                window.scrollTo(0, 0);
                setAlert({ state: true, variant: "danger", text: "Image size should be less that 700kb." })
            }
            setBannerForm({ ...bannerForm, img: dataUrl })
            console.log(bannerForm);
        } else {
            setBannerForm({ ...bannerForm, [e.target.files]: e.target.value });
        }
    }




    useEffect(() => {
        console.log(formData);
    }, [formData]);

    const handleSubmit = async () => {
        window.scrollTo(0, 0);
        try {
            console.log(form);
            if (form) {
                const { aboutDoctor, address, hospital, experience, expertise, fees, img, name } = formData;
                if (!aboutDoctor || !address || !hospital || !experience || !expertise || !fees || !img || !name) {
                    throw new Error("Not valid form")
                }
                const { data } = await addDoctor(formData);
                if (data.success) {
                    setAlert({ state: true, text: "Hurray! One more doctor added successfully!", variant: "success" })
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000)
                } else {
                    setAlert({ state: false, text: "Error Occured", variant: "danger" });
                }
            } else {
                console.log("here");
                const { aboutScheme, applicant, applyLink, img, issuedBy, keyFeature, name } = formData;
                if (!aboutScheme || !applicant || !applyLink || !issuedBy || !keyFeature || !img || !name) {

                    throw new Error("Not valid form")
                }
                const { data } = await addScheme(formData);
                console.log(data);
                if (data.success) {
                    setAlert({ state: true, text: "Hurray! One more scheme added successfully!", variant: "success" });
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000)
                } else {
                    setAlert({ state: false, text: "Error Occured", variant: "danger" });
                }
            }
        } catch (err) {
            console.log("err : ", err.message);
        }

    };

    const handleAddToGallery = async () => {
        const { data } = await addToGallery(galleryForm);
        if (data && data.success) {
            window.scrollTo(0, 0);
            setAlert({ state: true, variant: "success", text: "Image added to gallery!" })
            setTimeout(() => {
                window.location.reload();
            }, 2000)
        } else {
            window.scrollTo(0, 0);
            setAlert({ state: true, variant: "danger", text: "Error Occured" })
        }

    }

    const getImages = async () => {
        const { data } = await getGalleryImages();
        console.log(data);
        if (data && data.success) {
            setGalleryImages(data.data)
        }

    }

    const handleBannerFormSubmit = async () => {
        const { data } = await addBanner(bannerForm);
        console.log(data);
        if (data.success) {
            window.scrollTo(0, 0);
            setAlert({ state: true, variant: "success", text: "Banner added successfully !" })
            setTimeout(() => {
                window.location.reload()
            }, 3000)

        } else {
            window.scrollTo(0, 0);
            setAlert({ state: true, variant: "danger", text: "Error Occured while adding banner" })
        }
    }

    const handleBanners = async () => {
        const { data } = await getBanners();
        if (data.success) {
            setBanners(data.data);
        }
    }
    return <PageLayout footer="false" header="false">
        <HeaderWithLayout />

        <div className="upload-container row" data-aos="fade-in" data-aos-delay="50">
            {/* <div className="alert-container off">
            <div className="alert alert-danger" role="alert">
                A simple danger alert—check it out!
            </div>
        </div> */}

            {alert.state && <Alert variant={alert.variant} dismissible>
                {alert.text}
            </Alert>}





            <div className="form-container-wrapper col-lg-7" data-aos="slide-up" data-aos-delay="75">
                <div className="heading">
                    <div className="add-row flex jcb aic">
                        <div className="add-column add-doctor" onClick={() => { setForm(1); setFormData({}) }}>
                            <h1 className={!form && "faded"}>Add Doctor</h1>
                        </div>
                        <div className="add-column add-scheme" onClick={() => { setForm(0); setFormData({}) }}>
                            <h1 className={form && "faded"}>Add Scheme</h1>
                        </div>
                    </div>
                    <div className={form ? "underline-bar alfs" : "underline-bar alfe"}>
                        <div className="underline-fill"></div>
                    </div>
                </div>

                <div className="form-container" data-aos="slide-up" data-aos-delay="150">
                    {form ? <div className="form">
                        <div className="flex jcb aic">
                            <label for="name">Name</label>
                            <input type="text"
                                name="name" required onChange={handleChange} />
                        </div>
                        <div className="flex jcb aic">
                            <label for="name">Expertise</label>
                            <input type="text"
                                name="expertise" required onChange={handleChange} />
                        </div>
                        <div className="flex jcb aic">
                            <label for="name">Experience</label>
                            <input type="text"
                                name="experience" onChange={handleChange} />
                        </div>
                        <div className="flex jcb aic">
                            <label for="name">Address</label>
                            <input type="text"
                                name="address" onChange={handleChange} />
                        </div>
                        <div className="flex jcb aic">
                            <label for="name">Hospital</label>
                            <input type="text"
                                name="hospital" onChange={handleChange} />
                        </div>
                        <div className="flex jcb aic">
                            <label for="name">Fees</label>
                            <input type="text"
                                name="fees" onChange={handleChange} />
                        </div>
                        <div className="flex jcb fdc">
                            <label for="desc">More Information about Doctor</label>
                            <input type="text" name="aboutDoctor" rows="4"
                                placeholder="" onChange={handleChange} />
                        </div>
                        <div className="flex jcb">
                            <label for="image">Upload Image <span><p className="image-warning">(Image size shoul be less than 200kb)</p></span>
                            </label>
                            <input type="file" id="image"
                                name="img" required onChange={handleChange} />
                        </div>
                        <div>
                            <button className="btn btn-submit" onClick={handleSubmit}><i className="fas fa-plus" style={{ paddingRight: 5 }}></i>Add New Doctor</button>
                        </div>
                    </div> : <div className="form">
                        <div className="flex jcb aic">
                            <label for="name">Name</label>
                            <input type="text"
                                onChange={handleChange} name="name" required />
                        </div>
                        <div className="flex jcb aic">
                            <label for="name">Issued By</label>
                            <input type="text"
                                onChange={handleChange} name="issuedBy" required />
                        </div>
                        <div className="flex jcb aic">
                            <label for="name">Benifit</label>
                            <input type="text"
                                onChange={handleChange} name="keyFeature" />
                        </div>
                        <div className="flex jcb aic">
                            <label for="name">Applicable</label>
                            <input type="text"
                                onChange={handleChange} name="applicant" />
                        </div>
                        <div className="flex jcb aic">
                            <label for="name">Apply Link</label>
                            <input type="text"
                                onChange={handleChange} name="applyLink" />
                        </div>
                        <div className="flex jcb fdc">
                            <label for="desc">More Information about Scheme</label>
                            <input type="text" name="aboutScheme" onChange={handleChange} rows="4"
                                placeholder="" />
                        </div>
                        <div className="flex jcb">
                            <label for="image">Upload Image <span><p className="image-warning">(Image size shoul be less than 200kb)</p></span></label>
                            <input type="file" id="image"
                                name="img" onChange={handleChange} required />
                        </div>
                        <div>
                            <button className="btn btn-submit" onClick={handleSubmit}><i className="fas fa-plus" style={{ paddingRight: 5 }}></i>Add New Scheme</button>
                        </div>
                    </div>}

                </div>

            </div>
            <div className="other-details-wrapper col-lg-4">
                <div className="gallery-form-wrapper">
                    <div className="heading">
                        <h1>Add To Gallery</h1>
                        <div className="underline-bar">
                            <div className="underline-fill"></div>
                        </div>
                    </div>
                    <div className="form-container" data-aos="slide-up" data-aos-delay="150">
                        <div className="form">


                            <div className="flex jcb fdc">
                                <label for="desc">Description about Image.</label>
                                <input type="text" name="description" rows="4"
                                    placeholder="" required onChange={handleGalleryFormChange} />
                            </div>
                            <div className="flex jcb">
                                <label for="image">Upload Image <span><p className="image-warning">(Image size shoul be less than 200kb)</p></span> </label>
                                <input type="file" id="image"
                                    name="img" required onChange={handleGalleryFormChange} />
                            </div>
                            <div>
                                <button className="btn btn-submit" onClick={handleAddToGallery}><i className="fas fa-plus" style={{ paddingRight: 5 }}></i>Add To Gallery</button>
                            </div>
                        </div>
                    </div>
                    <div className="form-container gallery-images">
                        <div className="banner-list gallery-image-list">
                            {galleryImages && galleryImages.map((item) => {
                                return <div className="flex jcb aic gallery-image-item">
                                    <img src={item.img || ""} alt="" />
                                    {/* <div className="gallery-item-edit">
                                    <form action="/deleteBanner" method="post" >
                                        <textarea type="text" name="id" value="This is description text" />
                                        <button type="submit" className="btn btn-delete">
                                            Delete <span><i className="fas fa-trash"></i></span>
                                        </button>
                                    </form>
                                </div> */}
                                    <p>{item.description || ""}</p>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                <div className="carousel-form-wrapper">
                    <div className="heading">
                        <h1>Add Banner</h1>
                        <div className="underline-bar">
                            <div className="underline-fill"></div>
                        </div>
                    </div>
                    <div className="form-container" data-aos="slide-up" data-aos-delay="150">
                        <div className="form">
                            <div className="flex jcb fdc">
                                <div className="flex jcb">
                                    <label for="image">Upload Image <span><p className="image-warning">(Image size shoul be less than 700kb)</p></span> </label>
                                    <input type="file" id="image"
                                        name="img" required onChange={handleBannerFormChange} />
                                </div>
                                <div>
                                    <button className="btn btn-submit" onClick={handleBannerFormSubmit}><i className="fas fa-plus" style={{ paddingRight: 5 }}></i>Add To Banners</button>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="banner-list">
                            {banners && banners.map((item) => {
                                return <div className="banner-item flex jcb aic">
                                    <img src={item.img} alt="" />
                                    <div><form action="/deleteBanner" method="post" >
                                        <button type="submit" className="btn btn-delete">
                                            Delete <span><i className="fas fa-trash"></i></span>
                                        </button>
                                    </form></div>
                                </div>
                            })}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </PageLayout>
}