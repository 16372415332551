import Carousel from 'react-bootstrap/Carousel';

function MyCarousel({data,imgs}) {

    console.log(imgs);
    console.log("Carousel data");
    return (
        <Carousel fade>
            {
                 data.map((item,index) => {
                    return <Carousel.Item>
                        <img preload
                            className="d-block w-100"
                            src={imgs ? imgs[index].img : item.src || ""}
                            alt={item.alt || ""}
                        />
                        <Carousel.Caption>
                            <h3>{item.label || ""}</h3>
                            <p>{item.text || ""}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                })
            }
           
        </Carousel>
    );
}

export default MyCarousel;