import axios from "axios"

export const addDoctor = async (data) => {
    try {
        console.log((localStorage.getItem("token")));
        const response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/add-doctor`, data, {
            headers: {
                "Authorization": localStorage.getItem("token")
            }
        })
        return response;
    } catch (err) {
        return err.message;
    }
}