import axios from "axios";

export const getDoctors = async (page, limit) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/get-doctors`, {
            params: { page, limit }
        });
        return response;
    } catch (err) {
        return err.message;
    }
};