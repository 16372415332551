export default function Parallax({name,src}) {
    return <div>
        <div className={`parallax-${name} parallax`}>
        </div>
        <style>
            {`.parallax-${name}{
                background-image:url("${src}")
            }`}
        </style>
        </div>
}