
export const DataUrl = (files, limit) => {
    return new Promise((resolve, reject) => {
        const file = files[0];
        console.log(file);


        // Check file size
        if (limit) {
            if (file.size > limit) {
                console.log("exceeds");
                resolve(false);
            }
        } else {
            if (file.size > 250000) {
                console.log("exceeds");
                resolve(false);
            }
        }


        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function () {
            resolve(reader.result);
        };

        reader.onerror = function () {
            reject(reader.error);
        };
    });
}
