import axios from "axios";
export const getSchemes = async (page, limit) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/schemes`, {
            params: { page, limit }
        })
        return response;
    } catch (err) {
        return err.message;
    }
}