import { useContext, useState } from "react"
import { loginUser } from "../service/login"
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../context/AccountProvider";

export default function AdminLogin() {

    const navigate = useNavigate();

    const {account,setAccount} = useContext(AccountContext);

    const [form, setForm] = useState(null);

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }



    const handleSubmit = async () => {
        const {data} = await loginUser(form);
        if(data.success){
            console.log("yes")
            navigate("/dashboard");
            localStorage.setItem("token",data.token);
            localStorage.setItem("userType","admin");
            setAccount(true);
        }else{
            alert("Invalid Credentials");
        }
    }
    return <div className="login-container-wrapper">
        <h1 className="admin-heading">Miira<span className="highlight">Madhyam</span></h1>

        <div className="row">
            <div className="col-lg-6 login-container">
                <div className="card">
                    <div className="card-body">
                        <form className="form" action="http://localhost:4000/api/login" method="POST">
                            <div className="form-group">
                                <label for="email">Admin ID</label>
                                <input type="text" className="form-control" name="email" onChange={(e) => { handleChange(e) }} />
                            </div>
                            <div className="form-group">
                                <label for="password">Password</label>
                                <input type="password" className="form-control" name="password" onChange={(e) => { handleChange(e) }} />
                            </div>
                            <div className="btn-container">
                                <button type="button" className="btn btn-dark" onClick={handleSubmit}>Login</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

        </div>
    </div>
}