export default function Gallery({data}) {
    return <div className="gallery-container" data-aos="fade-in">

        <div className="heading-gallery">
            <h1>Our <span className="highlight">Gallery</span></h1>
            <div className="underline"></div>
        </div>




        <div className="gallery-wrapper" data-aos="slide-up">

            <div className="flex" style={{flexWrap:"nowrap"}}>
                <div className="col-lg-4 col-md-4 col-sm-4 mb-4 mb-lg-0 gallery-row flex fdc">

                    <div className="gallery-img-container smaller-window">
                        <img src={data[0].img || ""}
                            alt={data[0].alt || ""}
                            className="w-100"
                        />
                        <div className="overlay">
                            <div className="image-desc">
                                <p>{data[0].description || "" }</p>
                            </div>
                        </div>
                    </div>

                    <div className="gallery-img-container larger-window">
                        <img
                            src={data[1].img || ""}
                            className="w-100"
                            alt={data[1].alt || ""}
                        />
                        <div className="overlay">
                            <div className="image-desc">
                                <p>{data[1].description || "" }</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 mb-4 mb-lg-0 gallery-row flex fdc">

                    <div className="gallery-img-container larger-window">
                        <img src={data[2].img || ""}
                            alt={data[2].alt || ""}
                            className="w-100"
                        />
                        <div className="overlay">
                            <div className="image-desc">
                                <p>{data[2].description || ""}</p>
                            </div>
                        </div>
                    </div>

                    <div className="gallery-img-container smaller-window">
                        <img
                            src={data[3].img || ""}
                            className="w-100"
                            alt={data[3].alt || ""}
                        />
                        <div className="overlay">
                            <div className="image-desc">
                                <p>{data[3].description || ""}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 mb-4 mb-lg-0 gallery-row flex fdc">

                    <div className="gallery-img-container smaller-window">
                        <img src={data[4].img || ""}
                            alt={data[4].alt || ""}
                            className="w-100"
                        />
                        <div className="overlay">
                            <div className="image-desc">
                                <p>{data[4].description || ""}</p>
                            </div>
                        </div>
                    </div>

                    <div className="gallery-img-container larger-window">
                        <img
                            src={data[5].img || ""}
                            className="w-100"
                            alt={data[5].alt || ""}
                        />
                        <div className="overlay">
                            <div className="image-desc">
                                <p>{data[5].description || ""}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
}