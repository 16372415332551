import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from './pages/LandingPage';
import Error from './pages/Error';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import Doctors from './pages/DoctorsPage';
import Schemes from './pages/SchemesPage';
import AdminLogin from './pages/AdminLogin';
import Dashboard from './pages/Dashboard';
import { AccountContext } from './context/AccountProvider';
import { useContext, useEffect } from 'react';

function App() {

  const { account, setAccount } = useContext(AccountContext);
  console.log(process.env.REACT_APP_BASEURL);
  console.log("This is the latest code");

  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("userType") === "admin") {
      setAccount(true);
    }
  }, [BrowserRouter,localStorage]);

  

  useEffect(()=>{
    console.log(account);
  },[account]);




  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/doctors' element={<Doctors />} />
          <Route path='/schemes' element={<Schemes />} />
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
