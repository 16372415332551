import List from "../components/List";
import PageLayout from "../layout/Pagelayout";
import dummyData from "../data/master/schemes.json"
import { getSchemes } from "../service/GetSchemes";
import { useContext, useEffect, useState } from "react";
import Pagination from "../components/Pagination";
import { AccountContext } from "../context/AccountProvider";

export default function Schemes() {

    const [page, setPage] = useState(1);
    const [schemes, setSchemes] = useState(null);
    const [totalResults, setTotalResults] = useState(1);
    const { account } = useContext(AccountContext);
    useEffect(() => {
        handleSchemes();
    }, [page])

    const handleSchemes = async () => {
        const { data } = await getSchemes(page, process.env.REACT_APP_LIMIT);
        if (data.success) {
            console.log(data.data);
            setSchemes(data.data);
            setTotalResults(data.totalResults);
        }
        console.log(dummyData.items);
    }


    return <PageLayout>

        <div>
            <div className="page-container" data-aos="fade-in">
                <div className="main-container">
                    <div className="list-box row justify-content-between">
                        <div className="col-lg-8 list-container" data-aos="fade-left" data-aos-delay="50">
                            <h1>{dummyData.heading || ""}</h1>
                            <p>
                                <span><i className="fas fa-check"></i></span> {dummyData.subHeading || ""}
                            </p>
                            <List data={schemes || dummyData.items || []} adminView={account ? true : false} />
                            <Pagination page={page} setPage={setPage} totalResults={totalResults} />
                        </div>
                        <div className="col-lg-4 side-panel"></div>
                    </div>
                </div>
            </div>
        </div>
    </PageLayout>

}