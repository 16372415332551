import axios from "axios";

export const deleteSchemes = async (id)=>{
    try{
        const response = await axios.delete(`${process.env.REACT_APP_BASEURL}/api/delete-scheme/${id}`,{headers:{
            "Authorization":localStorage.getItem("token")
        }});
        return response;
    }catch(err){
        return err.message;
    }
}