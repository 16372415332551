export default function Footer() {
    return <footer>
        <main className="main-footer flex jcb" data-aos="fade-in" data-aos-delay="50" data-aos-easing="ease">
            <div className="footer-detail">
                <div className="contact-detail">
                    <div className="box">
                        <div className="flex">
                            <div className="icon">
                                <a href="mailto:miiramadhyam@gmail.com" className="mail"><i className="fa-regular fa-envelope"></i></a>
                            </div>
                            <div className="key">
                                <a href="mailto:miiramadhyam@gmail.com" className="mail">miiramadhyam@gmail.com</a>
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="flex">
                            <div className="icon">
                                <i className="fa-solid fa-phone"></i>
                            </div>
                            <div className="key">
                                +91 93190 11199
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="flex">
                            <div className="icon">
                                <i className="fa-solid fa-location-dot"></i>
                            </div>
                            <div className="key">
                                Jeoni Mandi, Agra-282001
                            </div>
                        </div>
                    </div>
                </div>
                <div className="others">
                    <div className="box flex aic">
                        <p>Follow us on</p>
                        <div className="social-media flex">
                            <i className="fab fa-facebook"></i>
                            <i className="fab fa-instagram"></i>
                            <i className="fab fa-twitter"></i>
                            <i className="fab fa-linkedin"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div className="line"></div>
            <div className="about-details">
                <h1>MiiraMadhyam</h1>
                <p>MiiraMadhyam aim to provide aids to deprived sections and nurture the understanding of social, economical & political aspects on their lives.</p>
                <p>We have a mission to harden their consciousness to enhance ability to get eqaul rights.</p>
            </div>
        </main>
        <div className="copyright">Copyright &copy; 2022 | <span className="highlight">MiiraMadhyam</span></div>
    </footer>
};