import axios from "axios";

export const addBanner = async (data)=>{
    try{
        const response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/add-banner`,data,{headers:{
            "Authorization":localStorage.getItem("token")
        }});
        return response;
    }catch(err){
        return err.message;
    }
}