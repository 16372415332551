import List from "../components/List";
import PageLayout from "../layout/Pagelayout";
import dummyData from "../data/master/doctors.json"
import { useContext, useEffect, useState } from "react";
import { getDoctors } from "../service/GetDoctors";
import Pagination from "../components/Pagination";
import { AccountContext } from "../context/AccountProvider";

export default function Doctors() {
    const [page, setPage] = useState(1);

    const [doctors, setDoctors] = useState(null);
    const [totalResults, setTotalResults] = useState(1);
    const {account} = useContext(AccountContext);

    useEffect(() => {
        handleDoctors();
    }, [page])

    const handleDoctors = async () => {
        const { data } = await getDoctors(page, process.env.REACT_APP_LIMIT);
        if (data.success) {
            setDoctors(data.data);
            setTotalResults(data.totalResults)
        }
    }
    return <PageLayout>

        <div>
            <div className="page-container" data-aos="fade-in">
                <div className="main-container">
                    <div className="list-box row justify-content-between">
                        <div className="col-lg-8 list-container" data-aos="fade-left" data-aos-delay="50">
                            <h1>{dummyData.heading || ""}</h1>
                            <p>
                                <span><i className="fas fa-check"></i></span> {dummyData.subHeading || ""}
                            </p>
                            <List data={doctors || dummyData.items || []} adminView={account ? true : false}/>
                            <Pagination page={page} setPage={setPage} totalResults={totalResults} showingOnThisPage={doctors && doctors.length || 0} />
                        </div>
                        <div className="col-lg-4 side-panel"></div>
                    </div>
                </div>
            </div>
        </div>
    </PageLayout>
}