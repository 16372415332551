import axios from "axios"

export const addToGallery = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/add-to-gallery`, data, {
            headers: {
                "Authorization": localStorage.getItem("token")
            }
        });
        return response;
    } catch (err) {
        return err.message;
    }
}