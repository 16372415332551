import { useState } from "react";

export default function Pagination({ setPage, totalResults,showingOnThisPage }) {

    const [firstNumber, setFirstNumber] = useState(1);

    const totalPages = Math.ceil(totalResults / process.env.REACT_APP_LIMIT);


    const requestPage = async (num) => {
        if (num === 0) {
            setFirstNumber(num + 1);
            setPage(num + 1);
        } else {
            setFirstNumber(num);
            setPage(num);
        }
    }


    return (
        <div className="mc-paginate">
            <p className="mc-paginate-title">Showing <b>{showingOnThisPage}</b> of <b>{totalResults}</b> Results</p>
            <ul className="mc-paginate-ul">
                <li className="mc-paginate-li" onClick={() => requestPage(firstNumber - 1)}>
                    <i class="fa-solid fa-chevron-left"></i>
                </li>
                <li className="mc-paginate-li active" onClick={() => requestPage(firstNumber)}><b>{firstNumber}</b></li>
                {totalPages > 1 && <li className="mc-paginate-li" onClick={() => requestPage(firstNumber + 1)}>{firstNumber + 1}</li>}
                {totalPages > 2 && <li className="mc-paginate-li" onClick={() => requestPage(firstNumber + 2)}>{firstNumber + 2}</li>}
                {totalPages > 3 && <li className="mc-paginate-li">...</li>}
                {totalPages > 3 && <li className="mc-paginate-li" onClick={() => requestPage(totalPages)}>{totalPages}</li>}
                <li className="mc-paginate-li" onClick={() => requestPage(firstNumber + 1)}>
                    <i class="fa-solid fa-chevron-right"></i>
                </li>
            </ul>
        </div>
    )
}