import Window from "../components/Window";
import PageLayout from "../layout/Pagelayout";

export default function AboutPage() {
    return <>
        <PageLayout>
            <Window
                superHeading="A little bit"
                title="About Us"
                src={"/Images/about.jpg"}
                textHead={"We serve the humanity"}
                text="MiiraMadhyam aim to provide aids to deprived sections and nurture the understanding of social, economical & political aspects on their lives.

            We have a mission to harden their consciousness to enhance ability to get eqaul rights."
            />
        </PageLayout>
    </>
}