import MyCarousel from "../components/Carousel";
import PageLayout from "../layout/Pagelayout";
import data from "../data/master/landingPage.json";
import Gallery from "../components/Gallery";
import Parallax from "../components/Parallax";
import Slider from "../components/Slider";
import Window from "../components/Window";
import { getGalleryImages } from "../service/GetGallery";
import { useEffect, useState } from "react";
import { getDoctors } from "../service/GetDoctors";
import { getSchemes } from "../service/GetSchemes";
import { getBanners } from "../service/GetBanners";

export default function LandingPage() {

    const [galleryImages, setGalleryImages] = useState(null);
    const [doctors, setDoctors] = useState(null);
    const [schemes, setSchemes] = useState(null);
    const [banners, setBanners] = useState(null);

    useEffect(() => {
        handleGalleryImages();
        handleDoctors();
        handleSchemes();
        handleBanners();
    }, [])


    const handleGalleryImages = async () => {
        const { data } = await getGalleryImages();
        if (data.success) {
            setGalleryImages(data.data);
        }
    }

    const handleDoctors = async () => {
        const { data } = await getDoctors(1, 3);
        if (data.success) {
            setDoctors(data.data);
            console.log(data);
        }
    }

    const handleSchemes = async () => {
        const { data } = await getSchemes(1, 3);
        if (data.success) {
            setSchemes(data.data);
            console.log(data);
        }
    }

    const handleBanners = async () => {
        const { data } = await getBanners();
        if (data.success) {
            setBanners(data.data);
            console.log(data);
        }
    }



    return <PageLayout>
        <MyCarousel
            data={data.carousel}
            imgs={banners && banners}
        />
        <Gallery
            data={galleryImages || data.gallery}
        />
        <Parallax
            name="government"
            src="/Images/parralax/parallax-gallery.jpg"
        />
        <Slider
            title={data.slider1.title}
            items={schemes || data.slider1.items}
            href={data.slider1.href}
        />
        <Parallax
            name="doctors"
            src="/Images/parralax/parallax-schemes.jpg"
        />
        <Slider
            title={data.slider2.title}
            items={doctors || data.slider2.items}
            href={data.slider2.href}
        />
        <Window
            superHeading="A little bit"
            title="About Us"
            src={"/Images/about.jpg"}
            textHead={"We serve the humanity"}
            text="MiiraMadhyam aim to provide aids to deprived sections and nurture the understanding of social, economical & political aspects on their lives.

            We have a mission to harden their consciousness to enhance ability to get eqaul rights."
        />
    </PageLayout >
}