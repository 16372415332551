export default function Slider({title,items,href}){
    return <div className="container-fluid scheme-section">
    <div className="head">
        <h1>{title.plain || title }<span className="highlight"> {title.highlighted || ""}</span></h1>
        <div className="underline"></div>
    </div>
    <div className="middle flex jcb">
        {items.length !== 0 && items.map((item)=>{return <div className="card-custom flex fdc" data-aos="slide-left">
            <div className="card-img ">
                <img preload src={item.src || item.img || ""} alt={item.alt || "" }/>
            </div>
            <div className="card-body">
                <h1 className="card-title">{item.name || ""}</h1>
                <div className="desc">
                    <p>{item.issuedBy || item.hospital}</p>
                    <p>{item.keyFeature || item.expertise }</p>
                </div>
                <div className="card-links">
                    <a href={item.href || "/"}>View More</a>
                </div>
            </div>
        </div>})}
        

    </div>
    <div style={{width:"100%"}} className="btn-view-div">
        <button className="btn btn-view-more view-more-schemes" data-aos="fade-in">
            <a href={href || "/"}>View More</a>
        </button>
    </div>
</div>
}