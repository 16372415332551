import { useEffect, useState } from "react"
import { deleteSchemes } from "../service/deleteSchemes";
import { deleteDoctor } from "../service/deleteDoctor";
import { Alert } from "react-bootstrap";

export default function List({ data, adminView }) {
    const [dropDialog, setDropDialog] = useState(null);
    const [currentBox, setCurrentBox] = useState(null);

    const [alert, setAlert] = useState({ state: false, text: "", variant: "" });

    // useEffect(()=>{
    //     setTimeout(()=>{
    //         setAlert({state:false,text:"",variant:""});
    //     },3000)
    // },[alert])

    console.log(adminView);

    const handleDropDialog = (index) => {
        setCurrentBox(index);
        if (!dropDialog && index !== currentBox) {
            setDropDialog({ display: "block", height: "auto", opacity: 1 });
        } else if (!dropDialog && index === currentBox) {
            setDropDialog({ display: "block", height: "auto", opacity: 1 });
        } else {
            setDropDialog(null);
        }
    }

    const handleDeleteItem = async (id, isScheme) => {
        console.log(id, isScheme);
        if (isScheme) {
            const { data } = await deleteSchemes(id);
            if (data.success) {
                window.scrollTo(0, 0);
                setAlert({ state: true, text: "Scheme's data deleted successfully", variant: "success" })
            }
        } else {
            const { data } = await deleteDoctor(id);
            console.log(data);
            if (data.success) {
                window.scrollTo(0, 0);
                setAlert({ state: true, text: "Doctor's data deleted successfully", variant: "success" })
            }
        }
    }
    return <>
        {alert.state && <Alert variant={alert.variant} dismissible>
            {alert.text}
        </Alert>}
        <div className="list" data-aos="fade-left" data-aos-delay="150">
            {data.length !== 0 && data.map((item, index) => {
                return <div className="profile-card" data-aos="fade-in" data-aos-delay="50">
                    <div className="profile flex">
                        <div className="img-container">
                            <img
                                src={item.img || ""}
                                alt={item.alt || ""}
                            />
                        </div>
                        <div className="profile-info flex jcb fdc">
                            <div>
                                <h2 className="name"><a href="/">{item.title || item.name} </a></h2>
                                <p>{item.issuedBy || item.hospital}</p>
                                <p>{item.keyFeature || item.expertise}</p>
                                {item.applyLink && <a rel="noreferrer" target="_blank" href={item.applyLink}>Apply Link</a>}
                            </div>
                            <div className="btns flex jcb aic">
                                <button className="btn btn-about" onClick={() => { handleDropDialog(index) }}>
                                    <div>Read More<span><i className="fa-solid fa-angle-down"></i></span></div>
                                </button>
                                {adminView && <button className="btn btn-delete" onClick={() => { handleDeleteItem(item.id, item.issuedBy ? true : false) }}>
                                    <div><span><i className="fa-solid fa-trash"></i></span> Delete</div>
                                </button>}
                            </div>
                        </div>
                    </div>
                    <div className="about-text" style={index === currentBox ? dropDialog : {}}>
                        <div className="text">
                            <p>{item.description || ""}</p>
                        </div>
                    </div>
                </div>
            })}
            <hr />
        </div>
    </>
}