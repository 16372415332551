import axios from "axios";

export const addScheme = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/add-scheme`, data, {
            headers: {
                "Authorization":localStorage.getItem("token")
            }
        })
        console.log(response);
        return response;
    } catch (err) {
        return err.message;
    }
}