
export default function Window({superHeading,title,src,textHead,text}) {
    return <>
        <div class="row about-container mx-auto">
            <div class="col-lg-10 mx-auto">
                <h3>{superHeading || ""}</h3>
                <h1>{title || ""}</h1>
                <div class="underline"></div>
                <div class="about-data-container flex alfs">
                    <div class="about-img-container">
                        <img src={src || "" } alt="" />
                    </div>
                    <div class="about-page-details">
                        <h2 className="text-align-left">{textHead || "" }</h2>
                        <p className="text-align-left">{text || "" }</p>
                        {/* <p>
                            We have a mission to harden their consciousness to enhance ability to get eqaul rights.
                        </p> */}
                    </div>
                </div>
            </div>

        </div>
    </>
}