import PageLayout from "../layout/Pagelayout";

export default function ContactPage() {
    return <>
        <PageLayout>
            <div className="row contact-container mx-auto">
                <div className="col-lg-4">
                    <div className="contact-page-detail">
                        <h1 className="text-align-left">Contact Us At</h1>
                        <div className="box">
                            <div className="flex">
                                <div className="icon">
                                    <a href="mailto:miiramadhyam@gmail.com" className="mail"><i className="fa-regular fa-envelope"></i></a>
                                </div>
                                <div className="key">
                                    <a href="mailto:miiramadhyam@gmail.com" className="mail">miiramadhyam@gmail.com</a>
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="flex">
                                <div className="icon">
                                    <i className="fa-solid fa-phone"></i>
                                </div>
                                <div className="key">
                                    +91 93190 11199
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="flex">
                                <div className="icon">
                                    <i className="fa-solid fa-location-dot"></i>
                                </div>
                                <div className="key">
                                    Jeoni Mandi, Agra-282001
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 letterbox">

                    <form action="/send" method="post" className="sender-form flex aic jcb">
                        <div className="sender-details">
                            <input type="text" placeholder="Name" name="name" required />
                            <input type="text" placeholder="Phone Number" name="phone" />
                            <input type="email" placeholder="Email" name="email" required />
                            <button type="submit" className="contact-form-submit">Send Gmail</button>
                        </div>
                        <div>
                            <textarea name="message" rows="10" placeholder="Comment" required></textarea>

                        </div>

                    </form>

                </div>
            </div>
        </PageLayout >
    </>
}