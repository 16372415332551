import Footer from "./Footer";
import Header from "./Header";
import Main from "./Main";

export default function PageLayout({header,footer,children}) {
    return <>
      {header !== "false" && <Header/>}
      <Main>{children}</Main>
      {footer !== "false" && <Footer/>}
    </>
}